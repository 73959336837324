import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { TextField, Stack, Tooltip } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { useLocales } from '../../locales';
import { setCustomerDrivingLicense } from '../../utils/conductorUtilities';
import Iconify from '../iconify';
export default function RHFTextField({ name, helperText, multiline, rows, type, inputRef, id, label, ...other }) {
    const { translate } = useLocales();
    const { control, setValue } = useFormContext();
    const handleWheel = (event) => {
        event.preventDefault();
    };
    return (_jsx(Controller, { name: name, control: control, defaultValue: "", render: ({ field, fieldState: { error } }) => (_jsxs(Stack, { sx: {
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: '10px',
                width: '100%',
            }, children: [_jsx(TextField, { sx: { width: '100%' }, ...field, name: name, label: `${translate(label)} `, onBlur: field.onBlur, InputProps: {
                        onWheel: handleWheel,
                    }, fullWidth: true, onWheel: handleWheel, value: field.value === null ? '' : field.value, inputRef: field.ref, multiline: multiline !== undefined && true, rows: rows, onChange: async (e) => {
                        field.onChange(e);
                        setCustomerDrivingLicense(name, setValue);
                    }, error: !!error, helperText: error && `${translate(error?.message)}`, ...other, type: type }), _jsx(Tooltip, { title: `${translate(helperText)}` || `${translate(label)}`, placement: "right-start", children: _jsx(Iconify, { icon: "ri:information-line", width: 23, sx: { cursor: 'help', color: '#9AA6B2', marginTop: error ? '-24px' : '0px' } }) })] })) }));
}
